<template>
  <div class="fale-conosco">
    <Voltar />
    <h1 class="titulo">Cancelar Assinatura</h1>
    <p>
      Olá <span class="texto-destaque">{{ user.nome }}</span>,
      preencha o formulário e nos informe o motivo de cancelamento.
    </p>

    <form class="form" @submit.prevent="handleSubmit">
      <div>
        <label for="email">E-mail</label>
        <input type="email" id="email" v-model="state.email" required />
      </div>
      <div class="margen-top">
        <label for="texto">Motivo de Cancelamento</label>
        <textarea name="mensagem" cols="30" rows="10" v-model="state.mensagem" required></textarea>
      </div>

      <div class="bt">
        <button type="submit" :disabled="state.isLoading">Enviar</button>
      </div>
    </form>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useToast } from 'vue-toastification'
import services from '../../services'
import Voltar from '../../components/Voltar'

export default {
  setup () {
    const toast = useToast()
    const user = JSON.parse(window.localStorage.getItem('user'))

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      nome: '',
      sobrenome: '',
      cpf: '',
      email: '',
      mensagem: ''
    })

    async function handleSubmit () {
      toast.clear()
      try {
        state.isLoading = true
        const { data, errors } = await services.faleconosco.cancelar({
          nome: user.nome,
          sobrenome: user.sobrenome,
          cpf: user.cpf,
          email: state.email,
          mensagem: state.mensagem
        })

        if (!errors && !data.error_message) {
          toast.success('Obrigado! Seu pedido de cancelamento foi recebido e será processado em até 1 dia útil!')
        } else {
          toast.error('Não foi possível efetuar seu cancelamento! Entre em contato com o Administrador! - 1')
        }

        state.isLoading = false
      } catch (error) {
        toast.error('Não foi possível efetuar seu cancelamento! Entre em contato com o Administrador! - 2')
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      }
    }

    return {
      state,
      user,
      handleSubmit
    }
  },
  components: { Voltar }
}
</script>
